.top_image{
    width: 100%;
    height: auto;
}

.container-fluid{
    padding: 0px!important;
}

.about_carousel{
    width: 100%;
    height: 400px!important;
}

.border_image{
    width: 100%;
    height: auto;
    border: 4px dotted #f2f2f2;
}

.circled_image{
    width: 100%;
    height: 300px;
}

.op{
    opacity: 1;
}

/* .op:hover{
    opacity: 1;
} */

body {
  text-align: center;
} 