body {
    background-color: #f2f2f2;
}

#vrpl_logo{
    display: block;
    margin: .5em auto .5rem auto;
    height: 5rem;
    max-height: 90px;
    max-width: 180px;
}

@media (max-width: 550px) {
    #vrpl_logo{
        display: block;
        margin: .5rem auto .5rem .5rem;
        height: 2.5rem;
        max-height: 45px;
        max-width: 90px;
    }
}

@media (min-width: 551px) and (max-width: 650px) {
    #vrpl_logo{
        display: block;
        margin: .5rem auto .5rem .5rem;
        height: 3.5rem;
        max-height: 55px;
        max-width: 120px;
    }
}

@media only screen and (max-width: 3000px) and (min-width: 770px) {
    .navbar {
        height: 6em;
    }
}

@media (max-width: 550px) { 
    .navbar {
        height: auto;
    }
}

@media (max-width: 550px) { 
    .navbar-light .navbar-toggler {
        padding: 4px;
        margin-right: 5px;
    }
}

.galleryImage {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.tabsColor{
    color: #00254d!important;
    text-align: center;
    margin: 0.5em;
    padding: 0.5rem;
}

.tabsColor:hover {
    font-weight: bolder;
}

.tabsColor::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.tabsColor::after{
    content: '';
    display: block;
    font-weight: 0;
    height: 2px;
    color: #00254d;
    box-shadow: none;
    transition: box-shadow .6s;
}

.tabsColor:hover::after {
    box-shadow: 0 5px 8px #00028d;
}

.dropdown-item:hover {
    color: #008f2f!important;
}

.dropdown-item::after{
    content: '';
    display: block;
    font-weight: 0;
    height: 2px;
    color: #00254d;
    box-shadow: none;
    transition: box-shadow .6s;
}

.dropdown-item:hover::after {
    box-shadow: 0 5px 8px #00028d;
}


#smt {
    color: #00254d!important;
    text-align: center;
    margin: 0.5em;
}

#dropdown-basic {
    color: #00254d!important;
    text-align: center;
    background-color: #f2f2f2;
    border: 0px;
    border-radius: 0px;
    padding-top: 0.5rem;
}

#dropdown-basic:hover {
    border-bottom: 1.5px #00254d solid;
}

.active, .insideActive {
    border-bottom: none!important;
}

.btn-success.focus, .btn-success:focus {
    border-color: transparent!important;
    box-shadow: 0 0 0 0 !important;
    border: 0px;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
    color: #00254d!important;
    text-align: center;
    box-shadow: 0 0 0 0rem #00254d!important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #00254d!important;
    color: #f2f2f2!important;
}

.btn-success.focus, .btn-success:focus {
    color: #00254d!important;
    text-align: center;
}

.dropdown-menu.show{ 
    color: #00254d!important;
    text-align: center;
    background-color: transparent!important;
    padding: 0px!important;
}

.dropdown-item {
    color: #00254d!important;
    text-align: center;
    background-color: #f2f2f2!important;
}

.btn-success {
    border-color: transparent;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    border-bottom: 1.5px solid #00254d!important;
    border-color: #00254d!important;;
}

.navback{
    background-color: #f2f2f2;
}

.navbar-light .navbar-nav .nav-link:hover{
    border-bottom: 1.5px #00254d  solid!important;
}

.dropdown-item:hover{
    border: 1px solid #00254d!important;
}

.active{
    border-color: #00254d ;
    border-bottom: 1.5px solid;
}

.insideActive{
    border-color: #00254d;
    border-bottom: 1.5px solid;
    background-color: #f2f2f2!important;
    color: #00254d!important;
}

.dropdown-divider{
    border-top: 1px solid #0154ab!important;
}

.cImage{
    width: 100%;
    height: 130%!important;
}

.map{
    margin-left: 5%;
    margin-right: auto;
    margin-top: 20px;
    padding: 0px!important;
}

.heading_home{
    color: #f2f2f2; 
    opacity: 1.0;
}

#kingsleyHomeImage {
    height: 5em;
}

.headingKingsley{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #f2f2f2;
}

.darkText {
    color: #f2f2f2;
    /* text-shadow: 0px 2px 2px navy; */
    margin: 3%;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 0%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.lightText {
    color: #00254d;
    /* text-shadow: 0px 2px 2px navy; */
    margin: 3%;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 0%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif 
}

.darkTextNoMargin {
    color: #f2f2f2;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif    
}

.borderDark {
    border: 10px solid #00254d!important;
}

.lightTextNoMargin {
    color: #00254d;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif     
}

.card:hover {
    box-shadow:
    0 2.8px 2.2px rgba(11, 0, 105, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
    ;  
}

.image-fluid{
    width: 100%;
    height: auto;
    border-top-right-radius: 30%;
    border-bottom-right-radius: 30%;
    border-top-left-radius: 30%;
    border-bottom-left-radius: 30%;
}

.container{
    padding: 0px!important;
    margin: auto!important;
}

.explore_more{
    background-color: #00254d!important;
    color: #f2f2f2 !important;
    border: 3px solid #f2f2f2!important;
}

.explore_moreLight{
    background-color: #f2f2f2!important;
    color: #00254d !important;
    border: 3px solid #00254d!important;
}


.footer{
    padding: 40px;
    margin-top: 40px;
    background-color: #0154ab;
    color: #F6AE2D;
    padding-bottom: 10px;
}

#contact-info > p > a {
    color: #F1F3F5;
}

.form-elements{
    padding-left: 0px!important;
}

.form-labels{
    margin-bottom: 0px; 
    padding-top: 5px;
}

.submit{
    background-color: #F6AE2D!important;
    color: #0154ab!important;
}

.submit:hover{
    background-color: green!important;
    color: white!important;
}

.hide {
    display: none!important;
}

.featured{
    height: 350px;
    width: 100%;
}

.logo{
    width: 150px;
    height: 150px;
}

.vectorIcons{
    width: 2rem;
    height: 2rem;
    padding-right: 5px;
}

.resolveIssues{
    font-size: 70%;
    opacity: 0.8;
    margin: 0px;
}
#formSuccess{
    font-size: 70%;
    opacity: 0.8;
    margin: 0px;
    display: none;
    color: green;
}
.general_vrpl_logo{
    display: block;
    margin: .5em auto .5rem auto;
    height: 5rem;
    max-height: 90px;
    max-width: 180px;
}
.general_group_logo{
    display: block;
    margin: .5em auto 0.5rem auto;
    height: 5rem;
    max-height: 90px;
    max-width: 180px;
}
@media (max-width: 550px) {
    .general_group_logo{
        display: block;
        margin: .5rem auto .5rem .5rem;
        height: 2.5rem;
        max-height: 45px;
        max-width: 90px;
    }
}

@media (min-width: 551px) and (max-width: 650px) {
    .general_group_logo{
        display: block;
        margin: .5rem auto .5rem .5rem;
        height: 3.5rem;
        max-height: 55px;
        max-width: 120px;
    }
}

.row {
    overflow: hidden;
}

.bigCarousel {
    max-width: 100%!important;
    margin-bottom: 0px!important;
}

.smallCarousel {
    max-width: 100%!important;
    max-height: 400px!important;
    margin-bottom: 0px!important;
}

.smallImage {
    max-width: 100%!important;
    max-height: 400px!important;
    padding-left: 0px!important;
}

.border_image {
    max-height: 100%!important;
    max-width: 100%!important;
}

.darkBlock {
    background-color: #00254d;
    padding: 2.5%;
}

.image-gallery-image {
    min-height: 450px;
    max-height: 450px!important;
}

.lightBlock {
    background-color: #f2f2f2;
    padding: 2.5%;
}

.lightBackground {
    background-color: #f2f2f2!important;
    border: none!important;
}

a:hover {
    text-decoration: none!important;
}

.darkBackground {
    background-color: #00254d!important;
}

[data-src] {
    min-width: 300px;
    min-height: 300px;
}

.footer {
    background-color: #00254d;
    color: #f2f2f2;
    margin-top: 0px;
}

    .aboutBackground {
        display: block;
        background-repeat: no-repeat;
        background-image: url('../images/Kingston/bird.jpg');
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        background-attachment: fixed;
        min-height: 35rem;
        height: 100%;
        position: relative;
    }

    @media only screen and (min-width: 0px) and (max-width: 949px) {
        .aboutBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .kingsleyBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .kingstonBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .kingroseBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .OmoroseBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .KingswoodBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .ShoppingBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .FarmvilleBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .LifestyleBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .HeightsBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .SkyBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .TownshipBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .IdealhomesBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
        .ResidencyBackground {
            min-height: 0px;
            height: 0px;
            background-image: none;
            padding-top: 5rem;
        }
    }

    @media only screen and (min-width: 950px) {
        .kingsleyBackground {
            display: block;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            background-image: url("../images/Kingsley/jogging_track.jpg");
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .kingstonBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Kingston/water2.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .kingroseBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Kingrose/gate4.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .OmoroseBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Omorose/18.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }

        .KingswoodBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Kingswood/carousel4.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }

        .ResidencyBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Residency/carousel3.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .FarmvilleBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Farmville/night_view_gate.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .LifestyleBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Lifestyle/gate.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .HeightsBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Heights/04.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .SkyBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Sky/club.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .ShoppingBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/99Shopping/02.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .TownshipBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Township/04.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    
        .IdealhomesBackground {
            display: block;
            background-repeat: no-repeat;
            background-image: url("../images/Idealhomes/05.jpg");
            background-size: 100%;
            background-position-x: 50%;
            background-position-y: 50%;
            background-attachment: fixed;
            min-height: 33rem;
            min-width: 100%;
            width: 100%;
            height: 100%;
            position: relative;        
        }
    }
   
    .layoutCarousel {
        max-height: 300px!important;
        max-width: 300px!important;
        min-width: 300px!important;
        min-height: 300px!important;
    }
    .dropdownLink {
        color: #00254d!important;
        text-align: center;
        background-color: #f2f2f2!important;
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230a0052' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230a0052' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
    .carousel-indicators li {
        background-color: #00254d;
    }

